import logo from '../assets/images/icons/logo.svg';
import MainPage from './projects/templates/MainPageTemplate';

export default function Home() {
	return (
		<MainPage>
			<div className="flex items-center h-full">
				<div className="flex items-center h-full backdrop-blur-sm px-3 min-w-[8rem]">
					<div className="opacity-20">
						<img src={logo} className="w-8 opacity-60" />
						<h1 className="font-thin text-4xl leading-none my-2">
							<span className="font-extralight text-2xl">&#8984;</span>R
						</h1>
						<p className="font-light">...lost.</p>
					</div>
				</div>
			</div>
		</MainPage>
	);
}
