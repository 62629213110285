import { Router as PreactRouter, Route } from 'preact-router';

import Home from '~/pages/Home';
import Projects from '~/pages/Projects';
import Project from '~/pages/projects/templates/Project';
import NotFound from '~/pages/404';
import Error from '~/pages/Error';
import paths from '~/enums/paths';
import Sandbox from '~/pages/Sandbox';

export default function Router() {
	return (
		<PreactRouter>
			<Route path={paths.root} component={Home} />
			<Route path={paths.projects} component={Projects} />
			<Route path="/projects/:id" component={Project} />
			<Route path={paths.sandbox} component={Sandbox} />
			<Route path={paths.notFound} component={NotFound} />
			<Route path={paths.error} component={Error} />
			<Route component={NotFound} default />
		</PreactRouter>
	);
}
