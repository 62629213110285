import { Link, route } from 'preact-router';
import { Grid } from '@mui/material';

import useProjects from '~/hooks/useProjects';
import paths from '~/enums/paths';
import MainPage from './MainPageTemplate';

export default function Project({ id }: { id: string }) {
	const { projects } = useProjects();
	const projectInfo = projects.find((p) => p.id.toString() === id);

	if (!projectInfo) {
		route('/404', true);
		return null;
	}

	return (
		<MainPage menus={[null, null, 'default', null]}>
			<Link href={paths.projects}>projects</Link>
			<h1 className="text-center">project template {id}</h1>
			<Grid container>
				<Grid item xs={12}>
					{projectInfo.description}
				</Grid>
			</Grid>
		</MainPage>
	);
}
