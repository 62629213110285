import { ThemeProvider, createTheme } from '@mui/material';
import { ProjectsProvider } from '~/stores/projects';
import Router from '~/components/navigation/Router';

const theme = createTheme({
	palette: {
		primary: {
			main: '#84db00',
			light: '#ccff66',
			dark: '#669900',
		},
		secondary: {
			main: '#cc3366',
			light: '#ee88bb',
			dark: '#991446',
		},
		night: {
			main: '#1a1a1a',
		},
	},
});

export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<ProjectsProvider>
				<Router />
			</ProjectsProvider>
		</ThemeProvider>
	);
}
