const images = Object.values(
	import.meta.glob('../assets/images/*.{png,jpg,jpeg,PNG,JPEG}', {
		eager: true,
		as: 'url',
	}),
);

const getRandomImage = (): string => {
	return images[Math.floor(Math.random() * images.length)];
};

export { images, getRandomImage };
