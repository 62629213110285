import { type ComponentChildren } from 'preact';
import { useRouter } from 'preact-router';

import ScreenSaver from '~/components/Screensaver';
import SiteNav from '~/components/navigation/SiteNav';
import { getRandomImage, removeLeadingAndTrailingSlashes } from '~/utils';
import type { Menu } from '~/types';

interface MainPageProps {
	menus?: Menu[];
	background?: string;
	children: ComponentChildren;
}

const defaultBackground = getRandomImage();

// const bg = 'bg-[radial-gradient(circle,rgba(0,0,0,0)_0%,rgba(10,10,20,0.15)_25%,rgba(40,40,80,0.3)_50%,rgba(8,8,48,0.2)_75%,rgba(0,0,0,0.5)_100%)]';
// const bg = 'bg-[rgba(0,0,0,0.5)]';
const bg =
	'bg-[radial-gradient(ellipse_at_top,rgba(80,8,40,0.2),transparent),radial-gradient(ellipse_at_bottom,rgba(6,100,6,0.25),transparent),radial-gradient(ellipse_at_left,rgba(48,6,96,0.45),transparent),radial-gradient(ellipse_at_right,rgba(0,0,0,0),transparent)]';

export default function MainPage({
	menus,
	background,
	children,
}: MainPageProps) {
	const [router] = useRouter();
	const pageClass = removeLeadingAndTrailingSlashes(router.url).replace(
		'/',
		'-',
	);

	return (
		<div
			id="site-container"
			className="relative bg-[#000000] h-screen min-h-screen"
		>
			<SiteNav menus={menus} />

			<div id="content-root" className="relative z-[1] p-5 w-full h-full">
				<main id="main-content" className={`${bg} h-full ${pageClass}`}>
					{children}
				</main>
			</div>

			<ScreenSaver background={background ?? defaultBackground} />
		</div>
	);
}
