import { type ComponentChildren, createContext } from 'preact';
import { useRef, useState, useEffect } from 'preact/hooks';

import ProjectsJson from '../assets/static/projects.json';
import type { Project } from '~/types';

interface IProjectsContext {
	loaded: boolean;
	projects: Project[];
}

const initialContext: IProjectsContext = {
	loaded: false,
	projects: [],
};

const ProjectsContext = createContext<IProjectsContext>(initialContext);

function ProjectsProvider({ children }: { children: ComponentChildren }) {
	const loaded = useRef<boolean>(false);
	const [projects] = useState<Project[]>(ProjectsJson.projects);

	const load = (): void => {
		loaded.current = true;
	};

	const projectsStore: IProjectsContext = {
		loaded: loaded.current,
		projects,
	};

	useEffect(() => {
		load();
	}, []);

	return (
		<ProjectsContext.Provider value={projectsStore}>
			{children}
		</ProjectsContext.Provider>
	);
}

export { ProjectsProvider, ProjectsContext };
