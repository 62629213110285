import useProjects from '~/hooks/useProjects';
import MainPage from './projects/templates/MainPageTemplate';
import ProjectExcerpt from './projects/templates/ProjectExcerpt';

export default function Projects() {
	const { projects } = useProjects();

	return (
		<MainPage>
			<div className="flex items-center h-full">
				<div className="mx-3 backdrop-blur-sm">
					<ul className="list-none m-0 p-0">
						{projects.map((p) => (
							<li key={p.id}>
								<ProjectExcerpt project={p} />
							</li>
						))}
					</ul>
				</div>
			</div>
		</MainPage>
	);
}
