interface ScreensaverProps {
	background: string;
}

export default function ScreenSaver({ background }: ScreensaverProps) {
	return (
		<div
			className="screensaver fixed top-0 left-0 right-0 bottom-0 w-full opacity-50 bg-no-repeat bg-center bg-cover"
			style={{ backgroundImage: `url(${background})` }}
		>
			<div className="screensaver__inner"></div>
		</div>
	);
}
