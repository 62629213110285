import { Link } from 'preact-router';
import { type Project } from '~/types';

export default function ProjectExcerpt({ project }: { project: Project }) {
	return (
		<div>
			<h1 className="font-thin m-0">
				<Link href={`/projects/${project.id}`}>{project.name}</Link>
			</h1>
			<p>{project.excerpt}</p>
		</div>
	);
}
