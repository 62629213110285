import { useEffect, useRef } from 'preact/hooks';
import { type HTMLAttributes } from 'preact/compat';

interface ICanvas extends HTMLAttributes<HTMLCanvasElement> {
	draw?: (ctx: CanvasRenderingContext2D) => void;
}

export default function Canvas({ draw, ...rest }: ICanvas) {
	const ref = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		if (ref.current) {
			const ctx = ref.current.getContext('2d');
			if (typeof draw === 'function' && ctx !== null) draw(ctx);
		}
	}, [draw, ref]);

	return <canvas ref={ref} {...rest} />;
}

Canvas.defaultProps = {
	draw: () => undefined,
	onClick: () => undefined,
};
