import { type ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { Modal } from '@mui/material';
import { Link } from 'preact-router';

import paths from '~/enums/paths';
import logo from '../../assets/images/icons/logo.svg';
import type { Menu } from '~/types';

interface SideNavProps {
	menus?: Menu[];
}

interface DefaultMenuProps {
	id: string;
	className: string;
	primary?: boolean;
	empty?: boolean;
	children?: ComponentChildren;
	onMouseOver(): void;
	onBlur(): void;
	onMouseLeave(): void;
}

const defaultMenus: Menu[] = [null, null, null, null];

const getPosition = (idx: string, isPrimary?: boolean): string | undefined =>
	({
		0: isPrimary ? 'top-5 left-5' : 'top-[0.625rem] left-[0.625rem]',
		1: 'top-[0.625rem] right-[0.625rem]',
		2: 'bottom-[0.625rem] right-[0.625rem]',
		3: 'bottom-[0.625rem] left-[0.625rem]',
	})[idx];

const primaryClasses =
	'w-10 pb-[200%] min-h-10 bg-no-repeat bg-left bg-contain bg-[url(/assets/images/logo.svg)]';

const emptyClasses =
	'w-6 h-6 bg-no-repeat bg-center bg-contain bg-[url(/assets/images/icons/reg_mark_thin.svg)]';

function DefaultPrimaryMenu() {
	return (
		<div className="primary__menu">
			<Link href={paths.root}>
				<img src={logo} alt="logo" className="h-14" />
			</Link>
			<p>about me etc</p>
			<p>an archive; or possibly, a crypt</p>
			<p className="disclaimer">&copy;2024</p>
		</div>
	);
}

// function MainMenu({  }: DefaultMenuProps) {
// 	return (
// 		<button aria-label="main menu" />
// 	);
// }

function DefaultGenericMenu({
	children,
	className,
	id,
	primary,
	empty,
	onBlur,
	onMouseLeave,
	onMouseOver,
}: DefaultMenuProps) {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const idx = id.split('-').pop() ?? '0';

	const handleOpen = (): void => {
		setIsOpen(true);
	};

	const handleClose = (): void => {
		setIsOpen(false);
	};

	return (
		<div
			className={`fixed w-10 h-10 transition duration-300 z-10 ${
				primary ? primaryClasses : ''
			} ${empty ? emptyClasses : ''} ${className} ${getPosition(idx, primary)}`}
			id={id}
			onBlur={onBlur}
			onMouseLeave={onMouseLeave}
			onMouseOver={onMouseOver}
		>
			{!empty && (
				<>
					<button
						onClick={handleOpen}
						className="block border-none w-full h-full cursor-pointer relative bg-[transparent]"
						aria-label="open menu"
					/>
					<Modal open={isOpen} onClose={handleClose}>
						<div
							className={`${getPosition(
								idx,
								primary,
							)} ${id}__modal p-5 absolute min-w-[50%] bg-grid-dark`}
						>
							{children}
						</div>
					</Modal>
				</>
			)}
		</div>
	);
}

export default function SiteNav({ menus }: SideNavProps) {
	const siteMenus = menus ?? defaultMenus;
	const [isHovered, setIsHovered] = useState(false);

	const handleHover = () => {
		// console.log('hover');
		setIsHovered(true);
	};

	const handleLeave = () => {
		// console.log('leave');
		// todo: if you unhover then rehover before the timeout expires, isHovered never gets reapplied.
		// reset timeout inside handlehover, change this to setinterval?
		setTimeout(() => {
			// console.log('timeout');
			setIsHovered(false);
		}, 1000);
	};

	return (
		<>
			{siteMenus.map((menu, index) => {
				if (menu === 'default') {
					return (
						<DefaultGenericMenu
							primary
							key={index}
							id={`menu-${index}`}
							className={isHovered ? 'opacity-100' : 'opacity-25'}
							onMouseOver={handleHover}
							onBlur={handleLeave}
							onMouseLeave={handleLeave}
						>
							<DefaultPrimaryMenu />
						</DefaultGenericMenu>
					);
				}
				if (menu === null) {
					return (
						<DefaultGenericMenu
							empty
							key={index}
							id={`menu-${index}`}
							className={isHovered ? 'opacity-100' : 'opacity-25'}
							onMouseOver={handleHover}
							onBlur={handleLeave}
							onMouseLeave={handleLeave}
						/>
					);
				}
				if (menu !== null) {
					return (
						<DefaultGenericMenu
							key={index}
							id={`menu-${index}`}
							className={`has-menu ${isHovered ? 'opacity-100' : 'opacity-25'}`}
							onMouseOver={handleHover}
							onBlur={handleLeave}
							onMouseLeave={handleLeave}
						>
							{menu}
						</DefaultGenericMenu>
					);
				}
				return null;
			})}
		</>
	);
}
