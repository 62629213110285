import Canvas from '~/components/Canvas';
import MainPage from './projects/templates/MainPageTemplate';

export default function Sandbox() {
	return (
		<MainPage>
			<Canvas
				width={800}
				height={800}
				draw={(ctx) => {
					ctx.fillStyle = 'green';
					ctx.fillRect(0, 0, 800, 800);
				}}
			/>
		</MainPage>
	);
}
